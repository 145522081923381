<template>
	<div>
		<Nav />
		<div>
			<router-view></router-view>
		</div>
	</div>
</template>
<script>
export default {
	components: {
		Nav: () => import("@/components/layouts/Nav"),
	},
}
</script>
